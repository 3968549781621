<template>
  <div class="mt-4">
    <MunicipalityPathGuard>
      <div class="w-full lg:w-3/4 xl:w-1/2 mx-auto">
        <ContactForm />
      </div>
    </MunicipalityPathGuard>
  </div>
</template>

<script>
import MunicipalityPathGuard from "@/components/common/MunicipalityPathGuard.vue";
import ContactForm from "@/components/common/utils/contact/ContactForm";

export default {
  components: { ContactForm, MunicipalityPathGuard }

};
</script>

<style lang="scss" scoped>

</style>